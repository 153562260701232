import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import ViewportPreviewStyles from "../wrapper/viewportPreviewWrapper";
import { QuickLinksContentElementProps } from "./quickLinksContentElement";

interface QuickLinksContentElementStyleScopedProps
  extends QuickLinksContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 */
export default function QuickLinksContentElementStyleScoped(
  props: QuickLinksContentElementStyleScopedProps
) {
  return (
    <>
      <style jsx global>{`
        :global(.${props.scopedSelector}) {
          display: flex;
          justify-content: center;
          .quick-links-container {
            justify-content: center;
            align-items: center;

            width: 100%;
            max-width: ${props.ceSettings?.maxWidth?.mobile
              ? props.ceSettings?.maxWidth?.mobile
              : "992"}px;

            @media (min-width: ${lowerBreakpointTabletPx()}) {
              max-width: ${props.ceSettings?.maxWidth?.tablet
                ? props.ceSettings?.maxWidth?.tablet + "px"
                : "992px"};
            }

            @media (min-width: ${lowerBreakpointDesktopPx()}) {
              max-width: ${props.ceSettings?.maxWidth?.desktop
                ? props.ceSettings?.maxWidth?.desktop + "px"
                : "992px"};
            }
            @media (min-width: ${lowerBreakpointWqhdPx()}) {
              max-width: ${props.ceSettings?.maxWidth?.wqhd
                ? props.ceSettings?.maxWidth?.wqhd + "px"
                : "992px"};
            }
          }

          .info-text {
            margin-right: ${props.ceSettings?.infoTextMargin?.right}px;
            margin-left: ${props.ceSettings?.infoTextMargin?.left}px;
            margin-bottom: ${props.ceSettings?.buttonsMargin?.bottom}px;
          }

          .button-wrapper {
            margin-right: ${props.ceSettings?.buttonsMargin?.right}px;
            margin-bottom: ${props.ceSettings?.buttonsMargin?.bottom}px;
          }
          .button-link-add {
            margin-top: 10%;
          }

          .buttons-with-info {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap; /* Allows the buttons to wrap onto a new line */
          }
        }
      `}</style>
      <ViewportPreviewStyles>
        <style>{`
          .${props.scopedSelector} .quick-links-container {
            max-width: ${props.ceSettings?.maxWidth?.mobile
              ? props.ceSettings?.maxWidth?.mobile
              : "992"}px !important;
          }

          @container content-wrapper (min-width: ${lowerBreakpointTabletPx()}) {
            .${props.scopedSelector} .quick-links-container {
              max-width: ${props.ceSettings?.maxWidth?.tablet
                ? props.ceSettings?.maxWidth?.tablet + "px"
                : "992px"} !important;
            }
          }

          @container content-wrapper (min-width: ${lowerBreakpointDesktopPx()}) {
            .${props.scopedSelector} .quick-links-container {
              max-width: ${props.ceSettings?.maxWidth?.desktop
                ? props.ceSettings?.maxWidth?.desktop + "px"
                : "992px"} !important;
            }
          }
          @container content-wrapper (min-width: ${lowerBreakpointWqhdPx()}) {
            .${props.scopedSelector} .quick-links-container {
              max-width: ${props.ceSettings?.maxWidth?.wqhd
                ? props.ceSettings?.maxWidth?.wqhd + "px"
                : "992px"} !important;
            }
          }
        `}</style>
      </ViewportPreviewStyles>
    </>
  );
}
